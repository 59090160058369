var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{ref:"panel",staticClass:"panel"},[_c('phone-ctn',[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.activeId),expression:"!activeId"}],staticClass:"nav-bar",on:{"click":function($event){$event.stopPropagation();return _vm.setBar(1)}}},[_c('nav-bar',_vm._b({attrs:{"disabled":true}},'nav-bar',_vm.project.config.navigation,false))],1),_c('div',{staticClass:"page"},[(_vm.initIframe)?_c('iframe',{ref:"iframe",staticClass:"page-iframe",style:({
					height: _vm.iframeHeight + 'px',
					pointerEvents: _vm.control.dragstatus ? 'none' : 'auto',
					minHeight: '668px',
				}),attrs:{"frameborder":"no","src":_vm.iframeUrl},on:{"load":_vm.init}}):_vm._e(),_c('div',{staticClass:"page-layer",style:({
					height: _vm.iframeHeight + 'px',
					zIndex: _vm.control.dragstatus ? '20' : null,
				}),attrs:{"type":"page"},on:{"dragover":_vm.layerMove}},_vm._l((_vm.widgetInfoList),function(item){return _c('control-widget-shape',{key:item.id,attrs:{"data":item},on:{"changeCurrWidget":_vm.changeCurrWidget,"showUpgrade":_vm.showUpgrade}},[_c('div',{ref:"layerWidget",refInFor:true,staticClass:"page-layer-widget",style:({ height: item.height + 'px' }),attrs:{"type":"widget","id":item.id}})])}),1)]),_c('el-tooltip',{attrs:{"effect":"light","content":"页面设置","placement":"bottom"}},[_c('div',{staticClass:"panel-set flex-center",on:{"click":function($event){$event.stopPropagation();return _vm.initPage($event)}}},[_c('i',{staticClass:"el-icon-setting"})])])],1),_c('el-dialog',{attrs:{"title":"","visible":_vm.dialogVisible,"width":"500px"},on:{"update:visible":function($event){_vm.dialogVisible=$event}}},[_c('div',{staticClass:"flex-center flex-column"},[_c('div',{staticClass:"f26 flex col-center"},[_c('i',{staticClass:"el-icon-warning f36 mr8",staticStyle:{"color":"#FA8C16"}}),_vm._v(" 高级功能")]),_c('div',{staticClass:"f16 mt20"},[_c('div',[_vm._v("此模块为高级功能，当前版本不支持，")]),_c('div',{staticClass:" mt12"},[_vm._v("如需升级请联系客服：19115975863")])])]),_c('span',{staticClass:"dialog-footer pb16",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{attrs:{"type":"primary","size":"small"},on:{"click":function($event){_vm.dialogVisible = false}}},[_vm._v("我知道了")])],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }