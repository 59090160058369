<!--
 * @Description: 物料属性面板
 * @Autor: WangYuan
 * @Date: 2022-01-13 15:31:38
 * @LastEditors: Tan Chao
 * @LastEditTime: 2022-09-20 11:33:44
-->
<template>
	<div class="Config">
		<config-ctn v-if="control.curWidget" :title="control.curWidget.name">
			<custom-schema-template v-if="show" :schema="curSchema" v-model="control.curWidget"></custom-schema-template>
		</config-ctn>

		<config-ctn v-else title="页面设置">
			<page-config></page-config>
		</config-ctn>
	</div>
</template>

<script>
import PageConfig from '@/components/PageConfig';
import { mapMutations } from 'vuex';
export default {
	components: {
		PageConfig,
	},
	inject: ['control'],
	watch: {
		'control.curWidget': {
			handler(newVal, oldVal) {
				if (newVal && oldVal) {
					if (newVal.id != oldVal.id) {
						// 多个相同组件在同一页面调用异步刷新切换
						this.reload();
					}
				}
			},
			deep: true,
		},
	},
	data() {
		return {
			show: true,
		};
	},
	methods: {
		...mapMutations(['setcurComponent']),
		reload() {
			this.show = false;
			setTimeout(() => {
				this.show = true;
			}, 100);
		},
	},
	reset(v) {
		// this.$set(this.control,'curWidget',v)
		console.log(v);
	},
	computed: {
		curSchema() {
			this.loading = true;
			console.log(this.control.curWidget);
			this.setcurComponent(this.control.curWidget);
			if (this.control.curWidget.component == 'McFixed') {
				if (this.control.curWidget.value) {
					console.log(this.control.curWidget);
					if (Array.isArray(this.control.curWidget.value.jump)) {
						let obj = Object.assign({}, this.control.curWidget.value.jump);
						this.control.curWidget.value.jump = obj;
					}
				}
			}
			if (this.control.curWidget.component == 'McTab') {
				if (this.control.curWidget.tabList.length) {
					this.control.curWidget.tabList.forEach((item) => {
						if (Array.isArray(item.jump)) {
							let obj = Object.assign({}, item.jump);
							item.jump = obj;
						}
					});
				}
			}
			if (this.control.curWidget.component == 'McSwiper') {
				if (this.control.curWidget.list.length) {
					this.control.curWidget.list.forEach((item) => {
						if (Array.isArray(item.jump)) {
							let obj = Object.assign({}, item.jump);
							item.jump = obj;
						}
					});
				}
			}
			return this.$fields[this.control.curWidget.component];
		},
	},
};
</script>

<style lang="scss"></style>
